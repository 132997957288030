/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable } from '@angular/core';
import { combineLatest, interval, Observable } from 'rxjs';
import { distinct, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { StompConsumer } from '../abstract/stomp-consumer';
import { Message as IMessage } from '@stomp/stompjs/esm6/i-message';
import { StompService } from './stomp.service';
import * as uuid from 'uuid';
import { HttpClient } from '@angular/common/http';
import { ctcTimeUpdate, fetchCtcTime } from '../../configuration/+state/configuration.actions';
import { selectServerTimeOffset } from '../../configuration/+state/configuration.selectors';
import dayjs, { Dayjs } from 'dayjs';
import { ClusterUrlProviderService } from './cluster-url-provider.service';

@Injectable({
    providedIn: 'root'
})
export class TimerService implements StompConsumer {
    private readonly SYNC_SERVER_TIME_TOPIC = `/topic/sync-server-time`;
    private readonly SYNC_SERVER_TIME_URI = `/api/sync-server-time`;

    public counter$ = interval(1000).pipe(startWith(0));
    public offset$: Observable<number>;
    public dayJsServerTime$: Observable<Dayjs>;
    public serverTime$: Observable<string>;
    public serverDate$: Observable<string>;

    constructor(
        private http: HttpClient,
        private store: Store,
        private stompService: StompService,
        private clusterUrlProviderService: ClusterUrlProviderService
    ) {
        this.stompService
            .watchAsync(this.SYNC_SERVER_TIME_TOPIC, uuid.v4(), this)
            .subscribe((message: IMessage) => this.store.dispatch(ctcTimeUpdate({ offset: new Date().getTime() - Number(message.body) })));
        this.offset$ = this.store.select(selectServerTimeOffset);
        this.dayJsServerTime$ = combineLatest([this.counter$, this.offset$.pipe(distinctUntilChanged())]).pipe(map(([, offset]) => dayjs().add(offset, 'ms')));
        this.serverTime$ = this.dayJsServerTime$.pipe(
            map((time) => time.format('HH:mm:ss')),
            distinct()
        );
        this.serverDate$ = this.dayJsServerTime$.pipe(
            map((time) => time.format('ddd, MMM D')),
            distinct()
        );
    }

    handleStompError(topic: string, receiptId: string, error: string): void {
        console.info(`Server time topic ${topic} subscription failed=${error} for receiptId=${receiptId}`);
    }

    handleSubscriptionEnd(topic: string, receiptId: string): void {
        console.info(`Server time topic ${topic} subscription end: ${receiptId}`);
    }

    handleSubscriptionInit(topic: string, receiptId: string): void {
        console.info(`Server time topic ${topic} subscription success: ${receiptId}`);
        this.store.dispatch(fetchCtcTime());
    }

    public requestServerTime() {
        const url = this.clusterUrlProviderService.getCtcUrl();
        return this.http.get<number>(`${url}${this.SYNC_SERVER_TIME_URI}`, { observe: 'body', responseType: 'json' });
    }
}
