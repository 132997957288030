/*******************************************************************************
 Copyright (C)  Motorola Solutions, INC.
 All Rights Reserved.
 Motorola Solutions Confidential Restricted.
 ******************************************************************************/

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as dayjs from 'dayjs';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { TimerService } from '../services/timer.service';
import * as utc from 'dayjs/plugin/utc';
import * as duration from 'dayjs/plugin/duration';

dayjs.extend(duration);
dayjs.extend(utc);

@Component({
    selector: 'msi-elapsed-time',
    template: `{{ displayedTime$ | async }}`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElapsedTimeComponent {

  private _startTime: number | string | undefined;

  private _endTime: number | string | undefined;

  public displayedTime$: Observable<string> | undefined;

  @Input()
  set startTime(value: number | string) {
    this._startTime = value;
    this.displayedTime$ = this.getTimeAgoStream();
  }

  @Input()
  set endTime(value: number | string) {
    this._endTime = value;
    this.displayedTime$ = this.getTimeAgoStream();
  }

  constructor(public timer: TimerService) { }

  private getTimeAgoStream() {
      return combineLatest([
          this.timer.counter$,
          this.timer.offset$.pipe(distinctUntilChanged())
      ]).pipe(map(([, offset]) => this.getTimeAgo(offset)));
  }

  private getTimeAgo(offset: number) {
    const now = new Date().getTime() - offset;
    const startTime = this._startTime ? this._startTime : now;
    const endTime = this._endTime ? this._endTime : now;
    const duration = dayjs.duration(
      dayjs(endTime).diff(dayjs(startTime))
    );
    const format = 'HH:mm:ss';
    return dayjs.utc(duration.asMilliseconds()).format(format);
  }
}
